import axios from 'axios';
import IMask from 'imask';

let funcs = {

    sendAjaxRequest: (url, postRequest = '', callbackFunction, headers) => {

        callbackFunction = callbackFunction || showRequestResult;

        // Объект XMLHttpRequest
        let xhr = new XMLHttpRequest();

        // Подготавливаем запроса
        xhr.open('POST', url, true);

        // Ставим заголовок что данные пришли с POST формы
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

        // Ставим заголовок что это ajax (Например для Laravel)
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

        // Accept Json
        xhr.setRequestHeader('Accept', 'application/json');

        // Поддержка laravel
        let CSRFToken = funcs.getLaravelCSRFToken();

        if (CSRFToken) {
            xhr.setRequestHeader(CSRFToken.header, CSRFToken.token);
        }

        // Устанавливаем дополнительные заголовки
        if (headers) {

            for (let header in headers) {

                if (!headers.hasOwnProperty(header)) continue;

                let headerValue = headers[header];

                xhr.setRequestHeader(header, headerValue);
            }
        }

        // Отправляем запрос
        xhr.send(postRequest);

        xhr.onreadystatechange = function () {

            // если запрос не завершён выходим
            if (xhr.readyState !== 4) return;

            // если запрос завершён с ошибкой
            if (xhr.status !== 200) {
                console.log(xhr.status + ': ' + xhr.statusText);
                return;
            }

            // Получаем ответ
            let response = xhr.responseText;

            try {
                response = JSON.parse(response)

            } finally {

                if (funcs.isFunc(callbackFunction)) {
                    callbackFunction(response);
                }
            }
        };

        function showRequestResult(result) {

            let errors = result.errors || [];

            if (result.error) {
                errors.push({'error': result.error})
            }

            errors.forEach(function (error) {

                for (let errorName in error) {

                    if (!error.hasOwnProperty(errorName)) continue;

                    let errorText = error[errorName];

                    console.log(errorName + ': ' + errorText);
                }
            });
        }
    },

    changeSelectedElement: function (selectiveElement, className, insideElement, previousElement) {

        insideElement = insideElement || document;

        // Мы либо передаём элемент у котрого нужно сменить класс либо находим по классу
        let previousSelectedElement = previousElement || insideElement.querySelector('.' + className);

        if (previousSelectedElement) {
            previousSelectedElement.classList.remove(className)
        }

        selectiveElement.classList.add(className);
    },

    isTouchDevice: function () {
        return 'ontouchstart' in window        // works on most browsers
            || navigator.maxTouchPoints;       // works on IE10/11 and Surface
    },

    forEach: function (collection, func) {
        return Array.prototype.forEach.call(collection, func);
    },

    find: function (collection, func) {
        return Array.prototype.find.call(collection, func);
    },

    copyTextToClipboard: function (text) {

        let textArea = document.createElement('textarea');

        // *** This styling is an extra step which is likely not required. ***
        //
        // Why is it here? To ensure:
        // 1. the element is able to have focus and selection.
        // 2. if element was to flash render it has minimal visual impact.
        // 3. less flakyness with selection and copying which **might** occur if
        //    the textarea element is not visible.
        //
        // The likelihood is the element won't even render, not even a flash,
        // so some of these are just precautions. However in IE the element
        // is visible whilst the popup box asking the user for permission for
        // the web page to copy to the clipboard.

        // Place in top-left corner of screen regardless of scroll position.
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em';
        textArea.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = 0;

        // Clean up any borders.
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';

        // Avoid flash of white box if rendered for any reason.
        textArea.style.background = 'transparent';
        textArea.value = text;

        document.body.appendChild(textArea);

        textArea.select();

        try {
            let successful = document.execCommand('copy');
            let msg = successful ? 'successful' : 'unsuccessful';

            console.log('Copying text command was ' + msg);
        } catch (err) {

            console.log('Oops, unable to copy');
        }

        document.body.removeChild(textArea);
    },

    isCopyTextToClipboardSupported: function () {

        let result;

        try {
            result = document.queryCommandSupported('copy')

        } catch (e) {
            result = false;
        }

        return result;
    },

    getLaravelCSRFToken: function () {

        let CSRFToken = document.querySelector('meta[name="csrf-token"]').content;

        return CSRFToken ? {header: 'X-CSRF-TOKEN', token: CSRFToken} : null;
    },

    numberFormat: function (number, precision) {

        // Округялем число возвращает кол-во цифр после точки
        if (precision) {
            number = parseInt(number).toFixed(precision)
        }

        // Преобразуем к строке
        number = String(number);

        // Разбивает 3 числа пробелом
        return number.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
    },

    normalizeNumber: function (price) {

        // Убираем пробелы
        price = price.replace(/ /g, '');

        // Преобразуем в integer
        return parseInt(price);
    },

    isString: function (mylet) {
        return (typeof mylet === 'string' || mylet instanceof String);
    },

    isFunc: function (mylet) {
        return typeof mylet === 'function';
    },

    isObject: function (mylet) {
        return typeof mylet === 'object' && mylet !== null;
    },

    isNotEmpty: function (array) {
        return array != null && array.length != null && array.length > 0;
    },

    isEmpty: function (array) {
        return !funcs.isNotEmpty(array);
    },

    getValueProperty: function (inputElement) {

        let inputElements = ['INPUT'];

        let tagName = inputElement.tagName;

        if (~inputElements.indexOf(tagName)) {
            return 'value';
        }

        return 'innerHTML';
    },

    setValue: function (element, value) {

        let valueProperty = funcs.getValueProperty(element);

        element[valueProperty] = value;
    },

    getValue: function (element) {

        let valueProperty = funcs.getValueProperty(element);

        return element[valueProperty];
    },

    gtagExistsCheck: function () {
        return (typeof (gtag) !== 'undefined' || typeof gtag === 'function');
    },

    translit: function (text) {

        // Удаляем пробелы
        text = text.trim();

        // Перевод в нижний регистр
        text = text.toLowerCase();

        // Символ, на который будут заменяться все спецсимволы
        let special = '';
        let space = '-';

        // Массив для транслитерации
        let transl = {
            'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'e', 'ж': 'zh',
            'з': 'z', 'и': 'i', 'й': 'j', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n',
            'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u', 'ф': 'f', 'х': 'h',
            'ц': 'c', 'ч': 'ch', 'ш': 'sh', 'щ': 'sch', 'ъ': special, 'ы': 'y', 'ь': special,
            'э': 'e', 'ю': 'yu', 'я': 'ya',
            ' ': space, '_': space, '`': special, '~': special, '!': special, '@': special,
            '#': special, '$': special, '%': special, '^': special, '&': special, '*': special,
            '(': special, ')': special, '-': space, '\=': special, '+': special, '[': special,
            ']': special, '\\': special, '|': special, '/': special, '.': special, ',': special,
            '{': special, '}': special, '\'': special, '"': special, ';': special, ':': special,
            '?': special, '<': special, '>': special, '№': special
        };

        let regex = new RegExp('[^0-9A-Za-z' + space + ']', 'gi');

        let result = '';
        let current_char = '';

        for (i = 0; i < text.length; i++) {

            // Если символ найден в массиве то меняем его
            if (transl[text[i]] !== undefined) {

                if (current_char !== transl[text[i]] || current_char !== special) {
                    result += transl[text[i]];
                    current_char = transl[text[i]];
                }
            }

            // Если не найден
            else {
                current_char = text[i].replace(regex, '');

                result += current_char;
            }
        }

        // Удаляет больше чем 2 пробельных символа
        result = result.replace(new RegExp(space + '+(?=' + space + ')', 'g'), '');

        return trimStr(result);

        function trimStr(s) {
            s = s.replace(/^-/, '');

            return s.replace(/-$/, '');
        }
    },

    clearForm: function (insideElement) {

        // Используем либо ID формы либо переданный элемент
        if (funcs.isString(insideElement)) {
            insideElement = document.querySelector(insideElement);
        }

        let inputsElements = funcs.getFormElements(insideElement);

        funcs.forEach(inputsElements, function (inputElement) {
            inputElement.innerHTML = '';
            inputElement.value = '';
        });
    },

    getFormElements: function (insideElement) {
        return insideElement.querySelectorAll('INPUT, TEXTAREA, SELECT');
    },

    getParam: function (cssTag, param, insideElement) {

        insideElement = insideElement || document;

        param = param || 'innerText';

        let element = insideElement.querySelector(cssTag);

        if (!element) {
            return null
        }

        return element[param];
    },

    setParam: function (cssTag, param, value, insideElement) {

        insideElement = insideElement || document;

        let element = insideElement.querySelector(cssTag);

        if (element) {
            element[param] = value;
        }

        return element;
    },

    formSubmit: function (formElement, callbackFunc, beforeSubmitCallbackFunc, doFormCheck = true, visibleFormElements = true) {

        if (funcs.isString(formElement)) {
            formElement = document.getElementById(formElement);
        }

        formElement.addEventListener('submit', doSubmitAttempt, false);

        function doSubmitAttempt(event) {

            event.preventDefault();

            // Проверка формы
            if (doFormCheck && !checkForm(formElement)) {
                return false;
            }

            let url = formElement.action;

            let postRequest = urls.getFormData(formElement, {onlyVisible: visibleFormElements});

            if (funcs.isFunc(beforeSubmitCallbackFunc)) {
                beforeSubmitCallbackFunc.call(formElement);
            }

            funcs.sendAjaxRequest(url, postRequest, callbackFunc);
        }
    },

    dispatchEvent: function (element, event, value) {

        let myEvent = new CustomEvent(event, {
            bubbles: true,

            // detail - стандартное свойство CustomEvent для произвольных данных
            detail: value
        });

        element.dispatchEvent(myEvent);
    },

    getInputValue: function (radioElementsName, insideElement) {

        if (!insideElement) {
            insideElement = document;

        } else if (funcs.isString(insideElement)) {
            insideElement = document.querySelector(insideElement)
        }

        let inputRadioElement = insideElement.querySelector('[name=' + radioElementsName + ']:checked');

        return inputRadioElement ? inputRadioElement.value : null;
    },

    getInputRadioValue: function (radioElementsName, insideElement) {

        insideElement = insideElement || document;

        let inputRadioElements = insideElement.querySelectorAll('[name=' + radioElementsName + ']');

        for (let i = 0, length = inputRadioElements.length; i < length; i++) {

            if (inputRadioElements[i].checked) {
                return inputRadioElements[i].value;
            }
        }
    },
};

let urls = {

    serializeObject: function (object, exceptData) {

        let postArray = [];

        let exceptArray = [];

        if (exceptData) {

            if (funcs.isString(exceptData)) {
                exceptArray.push(exceptData);
            }

            if (Array.isArray(exceptData)) {
                exceptArray = exceptData;
            }
        }

        for (let objectProperty in object) {

            if (!object.hasOwnProperty(objectProperty)) {
                continue;
            }

            if (~exceptArray.indexOf(objectProperty)) {
                continue;
            }

            let objectValue = object[objectProperty];

            postArray.push(encodeURIComponent(objectProperty) + '=' + encodeURIComponent(objectValue));
        }

        return postArray.join('&');
    },

    addToPostString(postString, name, value) {
        return postString + '&' + name + '=' + encodeURIComponent(value);
    },

    getURLSeparator: function (url) {
        return ~url.indexOf('?') ? '&' : '?'
    },

    changeGetParam: function (url, param, paramValue, options) {

        options = options || {};

        let dontAddIfExists = options.dontAddIfExists;
        let removeEmptyFlag = options.removeEmpty;

        let urlParts = url.split('#');

        let newUrl = urlParts[0];
        let urlHash = urlParts[1];

        let removeEmpty = removeEmptyFlag && !paramValue;

        // Если url содержит параметр
        if (~url.indexOf(param + '=')) {

            // paramRegexString = '(.*' + param + '=)([^&]+)(.*)';
            let paramRegexString = '(.*)(' + param + '=)([^&]+)(&?)(.*)';

            let paramRegex = new RegExp(paramRegexString);

            // Если нужно удалить пустой параметр (и удаляем &)
            if (removeEmpty) {

                newUrl = newUrl.replace(paramRegex, '$1$5');

                // Удаляем знак ? или & в конце (через одно выражение не получилось)
                newUrl = newUrl.replace(/[?|&]$/, '');

            } else {
                newUrl = newUrl.replace(paramRegex, '$1$2' + paramValue + '$4$5');
            }

        } else if (!dontAddIfExists && !removeEmpty) {

            let urlSeparator = urls.getURLSeparator(url);

            newUrl = newUrl + urlSeparator + param + '=' + encodeURIComponent(paramValue);
        }

        if (urlHash) {
            newUrl = newUrl + '#' + urlHash;
        }

        return newUrl;
    },

    getFormData: function (formElement, options) {

        options = options || {};

        let onlyVisible = options.onlyVisible;
        let skipEmpty = options.skipEmpty;
        let returnArray = options.returnArray;

        let returnObject = options.returnObject;
        let onlyNames = options.onlyNames;
        let ampersand = options.ampersand;

        let formMainElement;

        let postStringArray = [];

        // Используем либо ID формы либо переданный элемент
        if (funcs.isString(formElement)) {
            formMainElement = document.querySelector(formElement);
        } else {
            formMainElement = formElement;
        }

        let formElements = funcs.getFormElements(formMainElement);

        funcs.forEach(formElements, function (formElement) {

            // Пропускаем неактивные
            if (formElement.disabled) {
                return;
            }

            // Пропускаем невидимые элементы
            if (onlyVisible && !formElement.clientWidth && formElement.type !== 'hidden') {
                return;
            }

            // Пропускаем ненужные элементы формы
            if (onlyNames && !~onlyNames.indexOf(formElement.name)) {
                return;
            }

            // Input type=[radio] или нет
            let inputRadioOrCheckbox = formElement.tagName === 'INPUT' && (formElement.type === 'radio' || formElement.type === 'checkbox');

            // Пропускаем не отмеченные input radio
            if (inputRadioOrCheckbox && !formElement.checked) {
                return;
            }

            // Пропускаем пустые элементы формы
            if (skipEmpty && !formElement.value) {
                return;
            }

            // Добавляем в массив
            if (returnArray || returnObject) {
                postStringArray.push([formElement.name, formElement.value]);
            } else {
                postStringArray.push(formElement.name + '=' + encodeURIComponent(formElement.value));
            }
        });

        // Возвращаем в качестве массива
        if (returnArray) {
            return postStringArray;
        }

        if (returnObject) {

            let postObject = {};

            postStringArray.forEach(function (item) {
                postObject[item[0]] = item[1];
            });

            return postObject;
        }

        // Переводдим в строку
        let postString = postStringArray.join('&');

        // Добавляем амперсанд впереди
        if (postString && ampersand) {
            postString = ampersand + postString;
        }

        return postString;
    },

    getCurrentCleanURL: function () {

        // Текущий URL без параметров
        return location.protocol + '//' + location.host + location.pathname;
    },

    removeHash: function () {
        history.pushState('', document.title, window.location.pathname + window.location.search)
    },

    makeRedirect: function (url, params) {

        // Не перезагружает если одна и та же ссылка и есть анкор
        if (url === window.location.href && !params) {
            window.location.reload();

        } else {

            let redirectUrl = url;

            if (params) {
                redirectUrl += '?' + params;
            }

            window.location.href = redirectUrl;
        }
    },

    reloadPage: function (postString) {

        let url = urls.getCurrentCleanURL();

        if (postString) {
            url = url + '?' + postString;
        }

        urls.makeRedirect(url);
    }
};

let dom = {

    createElement: function (elementName, options) {

        /* USING:

               tooltipElement = dom.createElement('elementName', {
                   elementText: 'PLEASE SELECT TIME',
                   className: ['tooltip2', 'tooltip-error'],
                   attributeName: 'id',
                   attributeValue: 'select-time-error'
               });

            */

        elementName = elementName || 'span';

        let attributeName = options.attributeName || false;
        let attributeValue = options.attributeValue || false;
        let elementText = options.elementText || '';
        let text = options.text || '';
        let className = options.className;
        let value = options.value;
        let name = options.name;
        let type = options.type;

        let element = document.createElement(elementName);

        if (attributeName) {

            let attribute = document.createAttribute(attributeName);

            if (attributeValue) {
                attribute.value = attributeValue;
            }

            element.setAttributeNode(attribute);
        }

        if (className) {

            if (Array.isArray(className)) {

                className.forEach(function (classNameValue) {
                    element.classList.add(classNameValue);
                });

            } else {
                element.classList.add(className)
            }
        }

        if (value) {
            element.value = value;
        }

        if (text) {
            element.text = text;
        }

        if (elementText) {
            element.innerHTML = elementText;
        }

        if (name) {
            let attribute = document.createAttribute('name');

            attribute.value = name;
            element.setAttributeNode(attribute);
        }

        if (type) {
            let attribute = document.createAttribute('type');

            attribute.value = type;
            element.setAttributeNode(attribute);
        }

        return element;
    },

    insertBefore: function (insertedElement, beforeElement) {
        return beforeElement.parentNode.insertBefore(insertedElement, beforeElement);
    },

    insertAfter: function (insertedElement, afterElement) {

        // Если передаём html
        if (typeof (insertedElement) === 'string') {
            afterElement.insertAdjacentHTML('afterEnd', insertedElement);

            return afterElement.nextSibling;
        }

        // Если передаём элемент
        else {
            return afterElement.parentNode.insertBefore(insertedElement, afterElement.nextSibling);
        }
    },

    getScrollWidth: function () {

        // Создадим элемент с прокруткой
        let div = document.createElement('div');

        div.style.overflowY = 'scroll';
        div.style.width = '50px';
        div.style.height = '50px';

        // При display:none размеры нельзя узнать
        div.style.visibility = 'hidden';

        document.body.appendChild(div);

        let scrollWidth = div.offsetWidth - div.clientWidth;

        document.body.removeChild(div);

        return scrollWidth;
    },

    increaseInputNameAttribute: function (inputElement, increaseNum) {

        increaseNum = increaseNum || 1;

        // Заменяем порядковый номер имени формы
        let inputName = inputElement.name;

        if (!inputName) {
            return;
        }

        let numRegex = /\[(\d+)]/;

        let numFound = inputName.match(numRegex);

        if (numFound) {
            let nextNum = +numFound[1] + increaseNum;

            inputElement.name = inputName.replace(numFound[0], '[' + nextNum + ']');
        }
    },

    clone: function (element, options) {

        let formNameArrayCount = options.formNameArrayCount;
        let exceptHidden = options.exceptHidden;
        let clonedElement = element.cloneNode(true);

        let formElements = funcs.getFormElements(clonedElement)

        let tooltipElements = clonedElement.querySelectorAll('[data-tooltip]');
        let borderErrorElements = clonedElement.querySelectorAll('.input-error');

        if (clonedElement.classList.contains('input-error')) {
            clonedElement.classList.remove('input-error')
        }

        funcs.forEach(formElements, function (inputElement) {

            if (formNameArrayCount) {

                let inputName = inputElement.name;
                let numRegex = /\[(\d+)]/;
                let numFound = inputName.match(numRegex);

                if (numFound) {
                    let nextNum = +numFound[1] + formNameArrayCount;

                    inputElement.name = inputName.replace(numFound[0], '[' + nextNum + ']');
                }
            }

            if (exceptHidden && inputElement.tagName === 'INPUT' && inputElement.type === 'hidden') {
                return;
            }

            if (inputElement.tagName === 'SELECT') {
                return;
            }

            if (inputElement.tagName === 'TEXTAREA') {
                inputElement.innerHTML = '';
            }

            inputElement.value = '';
        });

        funcs.forEach(tooltipElements, function (tooltipElement) {
            tooltipElement.remove();
        });

        funcs.forEach(borderErrorElements, function (errorElement) {
            errorElement.classList.remove('input-error')
        });

        return clonedElement;
    }
};

function Slider(options) {

    // ID Слайдера
    let sliderID = options.sliderID || 'slider';

    // Слайдер-контейнер
    let sliderElement = document.getElementById(sliderID);

    if (!sliderElement) return;

    let slideElementsCSS = options.imageElementsCSS || '[data-slide]';

    let controlsTagCSS = options.controlsTagCSS || 'button';

    // Интервал смены слайдов
    let timeInterval = options.timeInterval || 6000;

    // Класс для активного изображения
    let visibleClassName = options.visibleClassName || 'active';

    // Максимальная высота баннера
    let maxHeight = options.maxHeight || null;

    // Минимальная высота баннера
    let minHeight = options.minHeight || 300;

    // Установка высоты чтобы слайдер помещался в экран
    let fullHeight = options.fullHeight || false;

    // Высота слайдера равная пропорции
    let fitImage = options.fitImage || false;

    // Зарезерированная высота для дополнтильного блока
    let reserveHeight = options.reserveHeight || 0;

    let changersAttribute = 'data-changer';

    let controlsWrapperAttribute = 'data-controls';

    let controlElementActiveClass = 'active';

    let imageElements = sliderElement.querySelectorAll(slideElementsCSS);

    let changerElements = sliderElement.querySelectorAll('[' + changersAttribute + ']');

    let controlsWrapperElement = sliderElement.querySelector('[' + controlsWrapperAttribute + ']');

    // Индекс текущего активного изображения
    let activeImageIndexNumber;

    // Коллекция кнопок (миниатюр) слайдера
    let controlsElements;

    // ID показанных слайдов
    let viewedSlides = [];

    let sliderIntervalID;

    // dispatchViewEvent(imageElements[0], 0);

    if (fullHeight) {

        // Устанавливаем высоту слайдера для того чтобы он помещался в экран по высоте
        setFullSliderHeight();

        // Меняем высоту слайдера при смене размеров окна
        window.addEventListener('resize', setFullSliderHeight);

    } else if (fitImage) {

        // Устанавливаем высоту слайдера для того чтобы он помещался в экран по высоте
        setSliderHeightToFitImage();

        // Меняем высоту слайдера при смене размеров окна
        window.addEventListener('resize', setSliderHeightToFitImage);
    }

    // Устанавлиаем интервал смены изображения
    setSliderInterval();

    // Устанавливаем обработчики смены слайдов на кнопки
    funcs.forEach(changerElements, function (changerElement, i) {

        changerElement.addEventListener('click', function () {

            clearSliderInterval();

            // если i = 0 -> forward = false
            activeImageIndexNumber = changeSlideImage(i);

            // Меняем активный элемент управления слайдом
            if (controlsElements) {
                funcs.changeSelectedElement(controlsElements[activeImageIndexNumber], controlElementActiveClass, controlsWrapperElement);
            }

            setSliderInterval();

        }, false);
    });

    // Остлеживание кликов по слайдам для генерации событий
    // sliderElement.addEventListener('click', dispatchClickEvent);

    // Устанавливаем возможность смены слайдов на минитюры
    if (controlsWrapperElement) {

        controlsElements = controlsWrapperElement.querySelectorAll(controlsTagCSS);

        Array.prototype.forEach.call(controlsElements, function (controlElement, i) {

            controlElement.addEventListener('click', function () {

                clearSliderInterval();

                activeImageIndexNumber = changeSlideImage(true, imageElements[i]);

                // Меняем активный элемент управления слайдом
                if (controlsElements) {
                    funcs.changeSelectedElement(controlsElements[activeImageIndexNumber], controlElementActiveClass, controlsWrapperElement);
                }

                setSliderInterval();
            }, false);
        });
    }

    function setSliderInterval() {

        if (timeInterval) {

            sliderIntervalID = setInterval(function () {

                activeImageIndexNumber = changeSlideImage(true);

                // Меняем активный элемент управления слайдом
                if (controlsElements) {
                    funcs.changeSelectedElement(controlsElements[activeImageIndexNumber], controlElementActiveClass, controlsWrapperElement);
                }


            }, timeInterval);
        }
    }

    function clearSliderInterval() {
        clearInterval(sliderIntervalID);
    }

    function changeSlideImage(forward, nextImage) {

        // Текущий активный слайд
        let currentSlide = sliderElement.getElementsByClassName(visibleClassName)[0];

        // Получаем следующий слайд
        nextImage = nextImage || getNextImage(currentSlide, forward);

        if (!nextImage) return;

        funcs.changeSelectedElement(nextImage, visibleClassName, false, currentSlide);

        // index активного изображения
        let slideIndex = Array.prototype.indexOf.call(imageElements, nextImage);

        // dispatchViewEvent(nextImage, slideIndex);

        return slideIndex;
    }

    function getNextImage(activeImage, forward) {

        // Конвертируем HTMLCollection в Array
        imageElements = Array.prototype.slice.call(imageElements);

        // Получаем индекс текущего изображения
        let currentImageIndex = imageElements.indexOf(activeImage);

        let nextImage;

        if (forward) {
            // Возвращаем следующий или первый элемент
            nextImage = imageElements[currentImageIndex + 1] || imageElements[0];
        } else {
            // Возвращаем предыдущий или последний элемент
            nextImage = imageElements[currentImageIndex - 1] || imageElements[imageElements.length - 1];
        }

        return nextImage;
    }

    // Высота слайдера для всей видимой области
    function setFullSliderHeight() {

        // Координаты слайдер-контейнера
        let sliderElementCords = sliderElement.getBoundingClientRect();

        // Расстояние от верха страница до слайдер-контейнера
        let sliderElementTop = sliderElementCords.top + pageYOffset;

        // Высота видимой области страницы
        let documentHeight = document.documentElement.clientHeight;

        // Высота баннера
        let sliderHeight = documentHeight - sliderElementTop - reserveHeight;

        // Если высота баннера больше максимальной высоты
        if (maxHeight && (sliderHeight > maxHeight)) {
            sliderHeight = maxHeight
        }
        // Если высота баннера меньше минимальной высоты
        else if (sliderHeight < minHeight) {
            sliderHeight = minHeight
        }

        // Устанавалием высоту видимой зоны
        sliderElement.style.height = sliderHeight + 'px';
        sliderElement.style.visibility = 'visible';
    }

    // Высота слайдера при динамическом рамзмера баннера но с заданным размером
    function setSliderHeightToFitImage() {

        // Ширина окна
        let windowWidth = document.documentElement.clientWidth;

        // Высота изображения полностью вмещаего в экран (3.35 = 2000 / 600)
        let sliderHeight = Math.round(windowWidth / 3.35);

        // Устанавалием высоту видимой зоны
        sliderElement.style.height = sliderHeight + 'px';
        sliderElement.style.visibility = 'visible';
    }

    // Событие просмотра слайда
    function dispatchViewEvent(slideImage, slideIndex) {

        if (viewedSlides.indexOf(slideIndex) !== -1) {
            return;
        }

        viewedSlides.push(slideIndex);

        dispatchEvent(slideImage, 'slide_view');
    }

    // Событие клика по слайду
    function dispatchClickEvent(event) {

        let slideElement = event.target.closest(slideElementsCSS);

        if (!slideElement) return;

        dispatchEvent(slideElement, 'slide_click');
    }

    // Генерация события с данными
    function dispatchEvent(slideElement, eventType) {

        let eventData = {
            slideName: slideElement.dataset.slide,
            slideId: slideElement.dataset.slideId
        }

        funcs.dispatchEvent(sliderElement, eventType, eventData);
    }

    // Высота слайдера при динамическом рамзмера баннера
    function setSliderHeightToFitImage2() {

        // Текущий активный слайд
        let currentSlide = sliderElement.getElementsByClassName(visibleClassName)[0];

        // Ссылка на изображение
        let imageUrl = currentSlide.style.backgroundImage.slice(4, -1).replace(/["']/g, '');

        let image = new Image();
        image.src = imageUrl;

        image.addEventListener('load', function () {

            // Ширина окна
            let windowWidth = document.documentElement.clientWidth;

            // Соотношение ширины и высоты
            let heightRatio = Math.round((image.width / image.height) * 100) / 100;

            // Высота изображения полностью вмещаего в экран
            let sliderHeight = Math.round((windowWidth / heightRatio) * 100) / 100;

            // Устанавалием высоту видимой зоны
            sliderElement.style.height = sliderHeight + 'px';
            sliderElement.style.visibility = 'visible';
        });
    }
}

const refreshSearchResults = (searchResult, wrapper) => {
    if (searchResult.length > 0) {
        let searchResultHtml = '';
        searchResult.map((e) => {
            let abreviature = '';
            abreviature = e.name.split(' ')[0][0];
            if(e.name.split(' ')[1]){
                abreviature = abreviature + e.name.split(' ')[1][0];
            }
            searchResultHtml += `
                <a href='${e.link}' class="header__navigation-item"><span>${abreviature}</span>${e.name}</a>
              `
        });
        wrapper.innerHTML = searchResultHtml;
    }else{
        wrapper.innerHTML = '';
    }
}

const handleMobileSearch = () => {
    const searchInput = document.querySelector('[data-url-search-mobile]');
    const searchButton = document.querySelector('[data-search-mobile]');
    const searchWindow = document.querySelector('[data-search-mobile-window]');
    const searchList = document.querySelector('[data-search-mobile-list]');
    let searchResult = [];

    searchButton.addEventListener('click', (e) => {
        if(e.target.hasAttribute('data-search-mobile-window')){
            searchWindow.classList.remove('active');
        }else{
            searchWindow.classList.add('active');
        }
    })

    searchInput.addEventListener('input', async (e) => {
        const searchQuery = e.target.value;
        if (searchQuery.length > 1) {
            await axios.get(e.target.getAttribute('data-url-search-mobile') + `?search=${e.target.value}`).then((res) => {
                if (res.status === 200) {
                    searchResult = res.data.data;
                    refreshSearchResults(searchResult, searchList);
                }
            });
        } else {
            searchList.innerHTML = '';
        }
    })
}

const handleSearch = () => {
    const searchInput = document.querySelector('[data-url-search]');
    const searchWindow = document.querySelector('[data-search-window]');
    document.addEventListener('click', (e) => {
        if(!e.target.hasAttribute('data-search-window')){
            searchWindow.classList.remove('active');
        }
    })
    let searchResult = [];
    searchInput.addEventListener('input', async (e) => {
        const searchQuery = e.target.value;
        if (searchQuery.length > 1) {
            await axios.get(e.target.getAttribute('data-url-search') + `?search=${e.target.value}`).then((res) => {
                if (res.status === 200) {
                    searchResult = res.data.data;
                    refreshSearchResults(searchResult, searchWindow);
                }
            });
            searchWindow.classList.add('active');
        } else {
            searchWindow.innerHTML = '';
            searchWindow.classList.remove('active');
        }
    })
}
handleSearch();
handleMobileSearch();

function QuantitySelector(options = {}) {
    // Modal Window Open Element IDs
    let quantitySelectorIDs = options.quantitySelectorIDs || false;
    let wrapperElementID = options.wrapperElementID || false;
    let quantitySelectorsAttribute = options.quantitySelectorsAttribute || 'data-quantity-selector';
    let modificatorAttribute = options.modificatorAttribute || 'data-modificator';
    let inputElementCSS = options.inputElementCSS || '[data-qty-input]';
    let allowMinus = options.allowMinus || false;
    let allowZero = options.allowZero || false;
    let zeroCallbackFunc = options.zeroCallbackFunc || false;
    let maxLimitAttribute = options.maxLimitAttribute || 'data-max-limit';
    let backgroundError = options.backgroundError || false;
    let callbackFunction = options.callbackFunction || false;
    // Запуск callback функции только на данном ID
    let callbackOnlyID = options.callbackOnlyID || false;
    // Массив связанных селекторов (Изменяются значения в обоих)
    let linkedQuantitySelectorIDS = options.linkedQuantitySelectorIDS || false;
    let errorClassName = 'error';
    let maxLimitValue = false;
    // Массив количественных селекторов
    let quantityElements = [];
    // Массив связанных количественных селекторов (на которых тоже меняем кол-во)
    let linkedQuantityElements = [];
    if (quantitySelectorIDs) {
        quantitySelectorIDs.forEach(function (id, i) {
            let quantityElement = document.getElementById(id);
            if (!quantityElement) {
                return;
            }
            quantityElements[i] = quantityElement;
        });
    } else if (wrapperElementID) {
        let wrapperElement = document.getElementById(wrapperElementID);
        if (!wrapperElement) {
            return;
        }
        wrapperElement.addEventListener('click', (event) => {
            let buttonElement = event.target.closest('[' + modificatorAttribute + ']');
            if (!buttonElement) return;
            let quantityElement = buttonElement.closest('[' + quantitySelectorsAttribute + ']');
            if (!quantityElement) return;
            handleQuantitySelectorClick(quantityElement, buttonElement)
        });
    } else {
        quantityElements = document.querySelectorAll('[' + quantitySelectorsAttribute + ']');
    }
    if (!quantityElements) return;
    // Modal Window Open Elements
    if (linkedQuantitySelectorIDS) {

        linkedQuantitySelectorIDS.forEach(function (id, i) {
            linkedQuantityElements[i] = document.getElementById(id);
        });
    }

    Array.prototype.forEach.call(quantityElements, function (quantityElement, numElement) {

        quantityElement.linkedQuantityElement = linkedQuantityElements[numElement];
        quantityElement.addEventListener('click', function (event) {
            let buttonElement = event.target.closest('[' + modificatorAttribute + ']');
            if (!buttonElement) return;
            handleQuantitySelectorClick(this, buttonElement)
        });
    });

    function handleQuantitySelectorClick(quantityElement, buttonElement) {
        let modificatorValue = parseInt(buttonElement.getAttribute(modificatorAttribute));
        let inputElement = quantityElement.querySelector(inputElementCSS);
        if (quantityElement.hasAttribute(maxLimitAttribute)) {
            maxLimitValue = parseInt(quantityElement.getAttribute(maxLimitAttribute));
        }
        let valueProperty = funcs.getValueProperty(inputElement);
        let inputElementOldValue = parseInt(inputElement[valueProperty]);
        let inputElementNewValue = inputElementOldValue + modificatorValue;
        let resultCallback = false;
        if (inputElementNewValue <= 0) {
            // Выходим если не разршён ноль и не разрешён минус
            if (!(allowZero || zeroCallbackFunc) && !allowMinus && inputElementNewValue === 0) {
                if (backgroundError) {
                    showErrorBackground(inputElement);
                }
                return;
            }
            // Выходим если не разшён минус
            if (!allowMinus && inputElementNewValue < 0) {
                if (backgroundError) {
                    showErrorBackground(inputElement);
                }
                return;
            }
        } else {
            // Выходим если есть лимит максимальной границы и он достигнут
            if (maxLimitValue && inputElementNewValue > maxLimitValue && inputElementNewValue > inputElementOldValue) {
                if (backgroundError) {
                    showErrorBackground(inputElement);
                }
                return
            }
        }
        if (zeroCallbackFunc && inputElementNewValue === 0) {
            // Подверждение на запуск функции (удаление)
            resultCallback = zeroCallbackFunc.call(this);
        }
        // Есть была зупущена коллбек функция и результат false - не изменяем значение
        if (resultCallback) {
            return;
        }
        // Изменяем значение селектора
        inputElement[valueProperty] = inputElementNewValue;
        // Запуск callback функции
        if (callbackFunction) {
            if (!callbackOnlyID || (callbackOnlyID && quantityElement.id === callbackOnlyID)) {
                callbackFunction.call(quantityElement, inputElementNewValue, modificatorValue, resultCallback);
            }
        }
        // Если есть связанный селектор в котором нужно тоже поменять значение
        if (quantityElement.linkedQuantityElement) {
            changeLinkedQuantityElementValue(inputElementNewValue);
        }

        function showErrorBackground(inputElement) {
            let transitionDuration = parseFloat(getComputedStyle(inputElement).transitionDuration) || 0;
            inputElement.classList.add(errorClassName);
            setTimeout(function () {
                inputElement.classList.remove(errorClassName);
            }, transitionDuration * 1000);

        }

        function changeLinkedQuantityElementValue(newValue) {
            let linkedQuantityElement = quantityElement.linkedQuantityElement;
            let inputElement = linkedQuantityElement.querySelector(inputElementCSS);
            let valueProperty = funcs.getValueProperty(quantityElement);
            inputElement[valueProperty] = newValue;
        }
    }
}

const handleInputPhoneMask = () => {
    if(document.getElementById('phone')){
        var phoneMask = IMask(
          document.getElementById('phone'), {
              mask: '+{38} (000) 000 00 00'
          });
    }
}


function Cart() {
    toggleDelivery();
    CartMenu();
    handleInputPhoneMask();
    const cartWindow = document.getElementById('cart-window');
    const cartModalWindow = document.getElementById('cart-modal-window');
    if(cartModalWindow) {
        document.addEventListener('click', (e) => {
            if(e.target.id === 'cart-modal-window'){
                cartModalWindow.classList.remove('active');
            }
        })
    }

    function changeCartPrice(quantitySelectorValue, modificatorValue) {
        let quantitySelector = this;
        let cartProductItem = quantitySelector.closest('[data-cart-item]')
        let urlQuantity = (modificatorValue === 1)
            ? cartProductItem.querySelector('[data-url-plus]').getAttribute('data-url-plus')
            : cartProductItem.querySelector('[data-url-minus]').getAttribute('data-url-minus');

        axios.post(urlQuantity).then(({data}) => {
            setTotalPriceFromResponse(data);
            setTotalProductsFromResponse(data);
        })
    }

    // Для корзины
    if (cartWindow) {
        new QuantitySelector({
            wrapperElementID: 'cart-window',
            quantitySelectorsAttribute: 'data-quantity-selector-cart',
            callbackFunction: changeCartPrice
        });
    }
    if (document.getElementById('cart-page')) {
        new QuantitySelector({
            wrapperElementID: 'cart-page',
            quantitySelectorsAttribute: 'data-quantity-selector-cart',
            callbackFunction: changeCartPrice
        });
    }

    function setTotalPriceFromResponse(responseData) {
        setTotalPrice(responseData.cartCost)
    }

    function setTotalPrice(cartPriceSum) {
        let cartTotalPrice = document.querySelectorAll('[data-cart-total-price]');

        cartTotalPrice.forEach(element => {
            element.innerHTML = cartPriceSum;
        });
    }

    function setTotalProductsFromResponse(responseData) {
        setTotalProducts(responseData.totalProducts)
    }

    function setTotalProducts(intTotalProduct) {
        let cartTotalProducts = document.querySelectorAll('[data-cart-value]');

        cartTotalProducts.forEach(element => {
            element.innerHTML = intTotalProduct;
        });
    }

    function addProductCartSignInCart(cardItem) {
        const addBtn = cardItem.querySelector('[data-url-add]');
        addBtn.classList.add('product_in_cart');
    }

    function removeProductCartSignInCart(cardItem) {
        const addBtn = cardItem.querySelector('[data-url-add]');
        addBtn.classList.remove('product_in_cart');
    }

    function CartMenu() {

        const cardWrapper = document.getElementById('card-wrapper');
        const cartPage = document.getElementById('cart-page');
        const cartWindow = document.getElementById('cart-window');

        if (cardWrapper) {
            initAddToCart(cardWrapper);
        }
        if (cartWindow) {
            initCartDelete(cartWindow, cardWrapper);
        }
        if (cartPage){
            initCartDelete(cartPage, cardWrapper);
        }

        function initAddToCart(cardWrapper) {
            cardWrapper.addEventListener('click', (event) => {
                let target = event.target;
                let cardBtn = target.closest('[data-card-button]');

                if (!cardBtn) return;
                if (!cardWrapper.contains(cardBtn)) return;

                let cardItem = target.closest('[data-card]');
                let cardID = cardItem.getAttribute('data-card');

                let cartProductIDs = getCartProductsIDs();

                if (cartProductIDs.includes(cardID)) { /* Проверка на совпадение */
                    console.log('Такой уже есть!')
                    return;
                }

                createCartProductItem(cardItem);

                function getCartProductsIDs() {
                    let cartItems = document.querySelectorAll('[data-cart-item]');
                    let cartProductIDs = [];
                    cartItems.forEach(element => {
                        // console.log(element.dataset.cartItem);
                        cartProductIDs.push(element.dataset.cartItem);
                    });

                    return cartProductIDs;
                }

                function addProductHtmlFromResponse(data) {
                    cartWindow.insertAdjacentHTML('afterbegin', data.renderedProduct);
                }

                async function createCartProductItem(cardItem) {
                    const addBtn = cardItem.querySelector('[data-url-add]');
                    const quantity = cardItem.querySelector('[data-qty-input]').textContent;
                    const route = addBtn.getAttribute('data-url-add');
                    await axios.post(route, {quantity: quantity}).then(({data}) => {
                        setTotalPriceFromResponse(data);
                        setTotalProductsFromResponse(data);
                        addProductHtmlFromResponse(data);
                        showAddItemNotice();
                        showEmptyCart();
                        addProductCartSignInCart(cardItem);
                    });

                }
            });
        }

        function initCartDelete(nodeElement, cardWrapper) {
            nodeElement.addEventListener('click', async (event) => {
                // event.preventDefault();
                let target = event.target;
                let cartBtn = target.closest('.cart__button-delete');
                if (target.className === 'cart__button-delete') {
                    const removeRoute = cartBtn.getAttribute('data-url-remove');
                    let cartItem = event.target.closest('[data-cart-item]');
                    let productId = cartItem.getAttribute('data-cart-item');
                    let cardItem = cardWrapper?.querySelector(`[data-card="${productId}"]`)
                    cartItem?.remove();
                    axios.post(removeRoute).then(({data}) => {
                        setTotalProductsFromResponse(data);
                        setTotalPriceFromResponse(data);
                        if (cardItem) {
                            removeProductCartSignInCart(cardItem);
                        }
                    });
                }

                showEmptyCart();
            });
        }

        function showAddItemNotice() {

            const mainElem = document.getElementById('main');

            const windowBuy = `
                <div class="window" data-notice-block>
                    <img src="/img/window.jpg" alt="">
                    <span class="window__text">Товар добавлен в корзину</span>
                </div>`;

            mainElem.insertAdjacentHTML('afterbegin', windowBuy);

            const noticeElement = document.querySelector('[data-notice-block]');

            setTimeout(() => noticeElement.remove(), 2000);
        }

    }

    function showEmptyCart() {
        let cartItems = document.querySelectorAll('[data-cart-item]');
        let cartEmpty = document.getElementById('cart-empty');
        let cartBottom = document.getElementById('cart-window-bottom')
        if (!cartEmpty && !cartBottom){
            return;
        }

        if (cartItems.length === 0) {
            cartEmpty.classList.add('active')
            cartBottom.classList.add('hidden')
            return
        }
        cartEmpty.classList.remove('active')
        cartBottom.classList.remove('hidden')
    }


}

document.addEventListener('DOMContentLoaded', Cart);


const toggleDelivery = () => {
    const deliveryForm = document.querySelector('[data-delivery-form]');
    const deliveryCheckboxes = document.querySelectorAll('[data-delivery-checkbox]');
    const paymentTypeCheckBoxes = document.querySelectorAll('[data-payment-type-checkbox]');
    if (!deliveryCheckboxes.length) {
        return;
    }
    let deliveryType = '';
    if (paymentTypeCheckBoxes[0].getAttribute('id') === 'cash') {
        paymentTypeCheckBoxes[0].setAttribute('checked', true);
    }
    if (deliveryCheckboxes[0].firstElementChild.getAttribute('id') === 'delivery') {
        deliveryCheckboxes[0].firstElementChild.setAttribute('checked', true);
        deliveryType = 'pickup';
    }
    deliveryCheckboxes.forEach(el => {
        el.addEventListener('click', e => {
            e.stopPropagation();
            deliveryType = e.currentTarget.firstElementChild.getAttribute('id');
            if (deliveryType === 'delivery') {
                deliveryForm.classList.add('active');
            } else {
                deliveryForm.classList.remove('active');
            }
        })
    })
}

function dropMenu() {
    const menu = document.querySelector('.dropmenu');
    const nav = document.querySelector('.header__navigation-window');
    const navBtn = document.querySelector('.header__navigation');
    const drop = document.querySelector('[data-drop]');
    const dropList = document.querySelector('[data-drop-list]');
    let cartModalWindow = document.getElementById('cart-modal-window');
    let cart = document.getElementById('cart')

    let flag = false;

    if (flag === true) {
        closeFlag()
    }


    changeClassToggle(menu);
    if (navBtn) {
        // changeClassToggleV2(navBtn, nav);
    }
    if (cart) {
        changeClassToggleV2(cart, cartModalWindow);
    }


    function changeClassToggle(item) {
        item.addEventListener('click', changeClass);
        document.addEventListener('click', changeClassMenu);
        flag = true

        function changeClassMenu(event) {

            let clickedElement = event.target;

            if (menu.contains(clickedElement)) {
                return;
            }

            menu.classList.remove('active')
            if (flag === true) {
                closeFlag()
            }
        }

        function changeClass() {
            item.classList.toggle('active');
            document.body.classList.toggle('no_scroll');
        }
    }

    function changeClassToggleV2(button, wrapper) {
        button.addEventListener('click', changeClass);
        document.addEventListener('click', changeClassNav);
        flag = true
        function changeClassNav(event) {
            let clickedElement = event.target;
            // button.contains(clickedElement) не работает
            if (wrapper.hasChildNodes(clickedElement)) {
                return;
            } else if (button.hasChildNodes(clickedElement)) {
                return;
            }else{
                wrapper.classList.remove('active')
            }
            if (flag === true) {
                closeFlag()
            }
        }

        function changeClass() {
            wrapper.classList.toggle('active');
        }
    }


    function closeFlag() {
        document.removeEventListener('click', (event) => {


        });
        flag = false;
        //    console.log('Флаг очищен');
    }

}

document.addEventListener('DOMContentLoaded', dropMenu);

function GoogleMap() {

    let map;

    function initMap() {
        map = new google.maps.Map(document.getElementById("map"), {
            center: {lat: -34.397, lng: 150.644},
            zoom: 8,
        });
    }
}

document.addEventListener('DOMContentLoaded', GoogleMap);
document.addEventListener('DOMContentLoaded', onLoad);

function onLoad() {

    new Slider({
        timeInterval: 5000,
        //fitImage: true
    });



    // Для товаров
    if(document.getElementById('product-qty-selector-desktop')
    || document.getElementById('product-qty-selector-mobile')){
        new QuantitySelector({
            quantitySelectorIDs: ['product-qty-selector-mobile', 'product-qty-selector-desktop'],
            linkedQuantitySelectorIDS: ['product-qty-selector-desktop', 'product-qty-selector-mobile']
        });
    }else{
        new QuantitySelector();
    }

}